import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {path: '/', redirect: '/login'},
        {path: '/login', component: () => import(`@/pages/main/login`)},
        {path: '/logout', component: () => import(`@/pages/main/logout`)},
        {
            path: '/main/password', component: () => import(`@/pages/main/layout_blank`),
            children: [
                {path: '', component: () => import(`@/pages/main/password`)},
            ]
        },
        {
            path: '/main', component: () => import(`@/pages/main/layout_admin`),
            children: [
                {path: '', redirect: '/main/dashboard'},
                {path: 'dashboard', component: () => import(`@/pages/dashboard`)},

                {path: 'certs', component: () => import(`@/pages/certs/index2`)},
                {path: 'certs/form', component: () => import(`@/pages/certs/form`)},
                {path: 'certs/m/:id', component: () => import(`@/pages/certs/member`)},
                {path: 'certs/u/:id', component: () => import(`@/pages/certs/import`)},

                {path: 'maillog', component: () => import(`@/pages/maillog`)},

                {path: 'help', component: () => import(`@/pages/help`)},

                {path: 'mail_tmpl/index', component: () => import(`@/pages/mail_tmpl/index`)},
                {path: 'mail_tmpl/mail', component: () => import(`@/pages/mail_tmpl/mail`)},
                {path: 'settings/password', component: () => import(`@/pages/main/password`)},

                {path: '*', component: () => import(`@/pages/main/notFound`)}
            ]
        },
        {path: '*', component: () => import(`@/pages/main/notFound`)}
    ]
})

export default router
