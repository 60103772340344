import Vue from 'vue'
import ElementUI from 'element-ui'
import App from './App.vue'
import http from './plugins/http.js'
import router from './router/index'
import store from './store/index'
import 'element-ui/lib/theme-chalk/index.css'
import BaiduMap from 'vue-baidu-map'
import QuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'
import Print from 'vue-print-nb'

Vue.use(Print);  //注册
Vue.use(QuillEditor)
Vue.use(BaiduMap, {
  ak: 'nuWah68S1WieW2AEwiT8T3Ro'
})
Vue.use(ElementUI);

Vue.prototype.version = window.version
Vue.prototype.$http = http
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
